<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['eyeBags'])">
      <single-answer-question
        title="label.eyeBags"
        :value="eyeBags"
        :options="eyeBagsOptions"
        :image="$options.bagsImage"
        dysfunction="skin-appearance"
        yes-no
        @input="onFieldChange('eyeBags', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      v-if="isQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="isEyeBagsTypeVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['eyeBagsType'])"
      >
        <single-answer-question
          id="eye-bags-type"
          title="label.eyeBagsType"
          :value="eyeBagsType"
          :options="$options.eyeBagTypesResponseOptions"
          dysfunction="skin-appearance"
          @input="onFieldChange('eyeBagsType', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { EYE_BAGS_TYPE_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/steps/skinAppearance';

import bagsImage from '@/modules/questionnaire/assets/images/skin-appearance/bags/bags.svg';

export default {
  name: 'BagsTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    QuestionWithErrorWrap,
    SingleAnswerQuestion
  },
  eyeBagTypesResponseOptions: EYE_BAGS_TYPE_RESPONSE_OPTIONS,
  bagsImage,
  mixins: [stepTemplateMixin],
  props: {
    eyeBags: {
      type: String,
      required: true
    },
    eyeBagsAiSelected: {
      type: String,
      default: ''
    },
    eyeBagsType: {
      type: String,
      default: ''
    },
    isEyeBagsTypeVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    eyeBagsOptions() {
      return flagAiSelectedOption(YES_NO_RESPONSE_OPTIONS, this.eyeBagsAiSelected);
    },
    isQuestionDividerVisible() {
      return this.isEyeBagsTypeVisible;
    }
  }
};
</script>
