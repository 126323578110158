<template>
  <bags-template
    :eye-bags="eyeBags"
    :eye-bags-ai-selected="eyeBagsAiSelected"
    :eye-bags-type="eyeBagsType"
    :field-errors="fieldErrors"
    :is-eye-bags-type-visible="isEyeBagsTypeVisible"
    @field-change="onFieldChange"
  />
</template>

<script>
import BagsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/bags/BagsTemplate';

import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const { requiredField } = fieldBuilder;

const AI_SELECTED_FIELDS = ['eyeBags'];
const FIELDS_TO_RESET = ['eyeBagsType'];

const FIELDS = [requiredField('eyeBags'), requiredField('eyeBagsType')];

export default {
  name: 'Bags',
  components: {
    BagsTemplate
  },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELDS), resetStoreValues],
  computed: {
    isEyeBagsTypeVisible() {
      return yes(this.eyeBags);
    }
  },
  watch: {
    eyeBags(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
        this.showNextStep();
        return;
      }

      this.scrollTo('#eye-bags-type');
    },
    eyeBagsType(newValue, oldValue) {
      if (!oldValue) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'eyeBagsType') {
        return this.isEyeBagsTypeVisible;
      }

      return true;
    }
  }
};
</script>
